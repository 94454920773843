// material
import { Container, Typography, IconButton, Stack, Button } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import styled from '@mui/material/styles/styled';
import { Icon } from '@iconify/react';
import backIcon from '@iconify/icons-ant-design/arrow-left';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useNavigate, useLocation } from 'react-router-dom';
import useSWR from 'swr';

import React, { useState } from 'react';
import cookies from 'js-cookie';

// components
import Page from '../../components/Page';
import { cookiesConfig } from '../../config';
import AlertConfrimation from '../../components/AlertConfirmation';
import TableAds from './TableAds';
import Skeleton from './SkeletonTable';
import ModalAds from './ModalAds';

// ----------------------------------------------------------------------

const Label = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: 250,
    marginBottom: 30,
    '& .backIcon': {
        marginRight: 10
    }
}));

const PaginationFooter = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 30
}));

export default function HomeBanner() {
    const navigate = useNavigate();
    const location = useLocation();
    const [totalPage, setTotalPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const page = new URLSearchParams(location.search || '').get('page');
    const token = cookies.get(cookiesConfig.authTokenKey);
    if (!token) {
        window.location.href = '/login';
    }

    const [dataIklan, setDataIklan] = React.useState([]);

    const { data, error, mutate } = useSWR(`/ads?allData=1&&page=${page || 1}`);

    React.useEffect(() => {
        if (data && data.data && data.data.data) {
            setDataIklan(data.data.data);
            setTotalPage(data.data.last_page || 1);
            setCurrentPage(data.data.current_page);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const [openAlert, setOpenAlert] = React.useState(false);
    const [openBanner, setOpenBanner] = React.useState(false);
    const [dataEdit, setDataEdit] = React.useState(null);

    const handleNext = () => {
        setOpenAlert(false);
    };

    const handleEdit = (param) => {
        setDataEdit(param);
        setOpenBanner(true);
    };

    const handleChangePage = (event, value) => {
        setCurrentPage(value);
        navigate(`/dashboard/config/ads?page=${value}`);
    };

    return (
        <Page title="Petanesia: Iklan">
            <AlertConfrimation
                open={openAlert}
                handleClose={() => setOpenAlert(false)}
                message="Yakin akan menyimpan perubahan ?"
                handleNext={handleNext}
            />
            {openBanner && (
                <ModalAds
                    open={openBanner}
                    handleClose={() => {
                        setOpenBanner(false);
                        setDataEdit(null);
                    }}
                    dataEdit={dataEdit}
                    token={token}
                    refetch={() => mutate()}
                />
            )}
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Label>
                        <IconButton
                            size="small"
                            className="backIcon"
                            onClick={() => navigate('/dashboard/blog')}
                        >
                            <Icon icon={backIcon} fontSize={18} />
                        </IconButton>
                        <Typography variant="h4" gutterBottom>
                            Pengaturan Iklan
                        </Typography>
                    </Label>
                    <Button
                        variant="contained"
                        onClick={() => setOpenBanner(true)}
                        startIcon={<Icon icon={plusFill} />}
                        disabled={!token}
                    >
                        Tambah Iklan
                    </Button>
                </Stack>
                {error || !data ? (
                    <Skeleton />
                ) : (
                    <TableAds
                        data={dataIklan}
                        handleEdit={handleEdit}
                        refetch={() => mutate()}
                        token={token}
                    />
                )}
                <PaginationFooter>
                    <Pagination
                        variant="outlined"
                        shape="rounded"
                        count={totalPage}
                        onChange={handleChangePage}
                        page={currentPage}
                    />
                </PaginationFooter>
            </Container>
        </Page>
    );
}
