/* eslint-disable react/prop-types */
import React from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import styled from '@mui/material/styles/styled';
import Autocomplete from '@mui/material/Autocomplete';
import Field from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import useSWR from 'swr';

const Container = styled(Card)(() => ({
    padding: 20,
    borderRadius: 5
}));

const Input = styled(Field)(() => ({
    marginBottom: 30
}));

const SwitchBox = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 30,
    padding: 5
}));

const FormAction = (props) => {
    const { formik, handleSubmit, isEdit, handleDelete } = props;
    const [category, setCategory] = React.useState([]);
    const { data, error } = useSWR('/category');

    React.useEffect(() => {
        if (data && data.data && data.data.data) {
            if (category.length === 0) {
                setCategory(data.data.data);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    if (formik.errors) console.log(formik.errors);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Container>
                    <SwitchBox>
                        <Typography>Publish</Typography>
                        <Switch
                            checked={formik.values.published}
                            onChange={(e) => formik.setFieldValue('published', e.target.checked)}
                        />
                    </SwitchBox>
                    <Autocomplete
                        // multiple
                        disabled={error || !data || !category || category.length === 0}
                        id="tags-standard"
                        options={category}
                        getOptionSelected={(option, value) => option === value}
                        getOptionLabel={(option) => option?.title || ''}
                        value={formik.values.category}
                        onChange={(event, newValue) => {
                            console.log(newValue);
                            formik.setFieldValue('category', newValue);
                        }}
                        name="category"
                        renderInput={(params) => (
                            <Input
                                {...params}
                                variant="outlined"
                                label="Kategori"
                                placeholder="Kategori Konten"
                                name="category"
                                error={formik.errors.category}
                            />
                        )}
                    />
                    <Input
                        value={formik.values.meta_title}
                        onChange={formik.handleChange}
                        error={formik.errors.meta_title}
                        label="Meta title"
                        name="meta_title"
                        fullWidth
                    />
                    {/* <Input label="Meta Deskripsi" name="desc" fullWidth multiline rows={2} /> */}
                    <Grid container spacing={2}>
                        <Grid xs={6} item>
                            <Button variant="outlined" fullWidth color="inherit">
                                Pratinjau
                            </Button>
                        </Grid>
                        <Grid xs={6} item>
                            <Button
                                variant="contained"
                                fullWidth
                                color="primary"
                                onClick={handleSubmit}
                            >
                                Post
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Grid item xs={12}>
                {isEdit && (
                    <Container sx={{ mt: 3 }}>
                        <Button variant="text" fullWidth color="primary" onClick={handleDelete}>
                            Hapus Postingan
                        </Button>
                    </Container>
                )}
            </Grid>
        </Grid>
    );
};

export default FormAction;
