// material
import { Grid, Container, Typography, IconButton } from '@mui/material';
import styled from '@mui/material/styles/styled';
import { Icon } from '@iconify/react';
import backIcon from '@iconify/icons-ant-design/arrow-left';
import { useNavigate } from 'react-router-dom';
import MuiAlert from '@mui/material/Alert';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import cookies from 'js-cookie';

// components
import Page from '../../components/Page';
import FormContent from './components/FormContent';
import FormAction from './components/FormAction';
import { cookiesConfig } from '../../config';
import uploadCloudinary from '../../utils/uploadCloudinary';

// ----------------------------------------------------------------------

const Label = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: 150,
    marginBottom: 30,
    '& .backIcon': {
        marginRight: 10
    }
}));

export default function NewBlog() {
    const navigate = useNavigate();
    const token = cookies.get(cookiesConfig.authTokenKey);
    if (!token) {
        window.location.href = '/login';
    }

    const ValidationAddress = {
        title: Yup.string().required('Judul harus di isi'),
        content: Yup.string().min(100, 'minimal 100 karakter').required('Konten harus di isi'),
        thumbnail: Yup.mixed().required('Gambar cover harus di isi'),
        short_desc: Yup.string()
            .min(50, 'Deskripsi singkat minimal 50 karakter')
            .max(160, 'Deskripsi singkat maksimal 160 karakter')
    };
    const formik = useFormik({
        initialValues: {
            title: '',
            meta_title: '',
            content: '',
            thumbnail: '',
            short_desc: '',
            published: 0
        },
        validationSchema: Yup.object().shape(ValidationAddress),
        onSubmit: async (values, { setSubmitting }) => {
            const baseUrl = process.env.REACT_APP_API_URL;
            window.openLoader(true);
            try {
                let thumbnailUrl = values.thumbnail;
                if (typeof values.thumbnail !== 'string') {
                    thumbnailUrl = await uploadCloudinary(values.thumbnail);
                }
                axios(`${baseUrl}/content`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        Authorization: `Bearer ${token}`
                    },
                    data: {
                        title: values.title,
                        content: values.content,
                        meta_title: values.meta_title || values.title,
                        thumbnail_url: thumbnailUrl,
                        short_desc: values.short_desc,
                        published: values.published ? 1 : 0
                    }
                })
                    .then(async () => {
                        setSubmitting(false);
                        window.toastMessage({
                            open: true,
                            saverity: 'success',
                            message: 'Kontent berhasil di simpan'
                        });
                        window.openLoader(false);
                        setTimeout(() => navigate('/dashboard/content'), 1000);
                    })
                    .catch((e) => {
                        console.log('gagal');
                        console.log(e);
                        setSubmitting(false);
                        window.toastMessage({
                            open: true,
                            saverity: 'error',
                            message: 'Kontent gagal di simpan'
                        });
                        window.openLoader(false);
                    });
            } catch (e) {
                console.log('gagal');
                console.log(e);
                setSubmitting(false);
                window.toastMessage({
                    open: true,
                    saverity: 'error',
                    message: 'Kontent gagal di simpan'
                });
            }
        }
    });

    return (
        <Page title="Petanesia: Konten | Buat Baru">
            <Container>
                <Label>
                    <IconButton
                        size="small"
                        className="backIcon"
                        onClick={() => navigate('/dashboard/content')}
                    >
                        <Icon icon={backIcon} fontSize={18} />
                    </IconButton>
                    <Typography variant="h4" gutterBottom>
                        Buat Konten
                    </Typography>
                </Label>
                <Grid container spacing={3}>
                    {Object.keys(formik.errors).length > 0 && (
                        <Grid item xs={12}>
                            <MuiAlert elevation={0} variant="filled" severity="error">
                                Ada field yang kosong silahkan cek kembali
                            </MuiAlert>
                        </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={8}>
                        <FormContent formik={formik} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <FormAction formik={formik} handleSubmit={formik.handleSubmit} />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
