/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import cookies from 'js-cookie';
// material
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

// swr
import { SWRConfig } from 'swr';

//
import { cookiesConfig } from '../../config';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import Loader from '../../components/Loader';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
        paddingTop: APP_BAR_DESKTOP + 24,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}));

const Alert = React.forwardRef((props, ref) => (
    <MuiAlert elevation={1} ref={ref} variant="filled" {...props} />
));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
    const [open, setOpen] = useState(false);
    const token = cookies.get(cookiesConfig.authTokenKey);
    if (!token) {
        window.location.href = '/login';
    }

    const [toast, setToast] = useState({
        open: false,
        saverity: 'succes',
        message: ''
    });

    const [openLoader, setOpenLoader] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setToast({
            ...toast,
            open: false
        });
    };

    const handleSetToast = (message) => {
        setToast({
            ...toast,
            ...message
        });
    };

    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            window.toastMessage = handleSetToast;
            window.openLoader = setOpenLoader;
        }
        // setMainMinimumHeight(refFooter.current.clientHeight + refHeader.current.clientHeight);
    }, []);

    return (
        <SWRConfig
            value={{
                fetcher: (resource, init) => {
                    const baseUrl = process.env.REACT_APP_API_URL;
                    return fetch(baseUrl + resource, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        },
                        ...init
                    })
                        .then((res) => {
                            if (!res.ok) {
                                const err = new Error('Not 2xx response');
                                err.response = res;
                                throw err;
                            }
                            return res.json();
                        })
                        .catch((err) => {
                            console.log(err);
                            throw err;
                        });
                }
            }}
        >
            <RootStyle>
                <Loader open={openLoader} />
                {toast.open && (
                    <Snackbar open={toast.open} autoHideDuration={1000} onClose={handleClose}>
                        <Alert
                            onClose={handleClose}
                            severity={toast.saverity}
                            sx={{ width: '100%' }}
                        >
                            {toast.message}
                        </Alert>
                    </Snackbar>
                )}
                <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
                <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
                <MainStyle>
                    <Outlet />
                </MainStyle>
            </RootStyle>
        </SWRConfig>
    );
}
