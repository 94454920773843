import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import useSWR from 'swr';
// material
import { Grid, Button, Container, Stack, Typography, Skeleton as MuiSkeleton } from '@mui/material';
// components
import Page from '../../components/Page';
import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from './components';
//
import Skeleton from './components/Skeleton';

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
    { value: 'latest', label: 'Latest' },
    { value: 'popular', label: 'Popular' },
    { value: 'oldest', label: 'Oldest' }
];

// ----------------------------------------------------------------------

export default function Content() {
    const [postData, setPostData] = useState([]);
    const [meta, setMeta] = useState({
        page: 1,
        lastPage: 1
    });
    const [search, setSearch] = useState('');
    const { data, error } = useSWR(
        `/content?withunpublish=true&search=${search}&page=${meta.page}`
    );

    useEffect(() => {
        if (!error && data && data.data && data.data.data) {
            const newMeta = {
                page: data.data.current_page,
                lastPage: data.data.last_page
            };
            if (meta.page === 1) {
                setPostData(data.data.data);
                setMeta(newMeta);
            } else {
                setPostData([...postData, ...data.data.data]);
                setMeta(newMeta);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    let errorData = false;
    let resdata = null;
    if (error) {
        errorData = true;
        if (
            error &&
            error.response &&
            error.response.status >= 400 &&
            error.response.status < 500
        ) {
            errorData = false;
            resdata = {};
        }
    }

    if (data) resdata = data;

    const handleScroll = () => {
        // To get page offset of last user
        // const lastUserLoaded = document.querySelector(`.grid-item:last-child`);
        const lastUserLoaded = document.querySelector('.latest-indicator');
        if (lastUserLoaded) {
            const lastUserLoadedOffset = lastUserLoaded.offsetTop + lastUserLoaded.clientHeight;
            const pageOffset = window.pageYOffset + window.innerHeight;
            if (pageOffset > lastUserLoadedOffset && !error && meta.lastPage > meta.page) {
                setMeta({
                    ...meta,
                    page: meta.page + 1
                });
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    if ((!resdata && postData.length === 0) || errorData) return <Skeleton />;

    return (
        <Page title="Dashboard: Konten | Petanesia">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Konten
                    </Typography>
                    <Button
                        variant="contained"
                        component={RouterLink}
                        to="/dashboard/content/new"
                        startIcon={<Icon icon={plusFill} />}
                    >
                        New Konten
                    </Button>
                </Stack>

                <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
                    <BlogPostsSearch
                        handleSearch={(value) => setSearch(value)}
                        defaultValue={search}
                    />
                    <BlogPostsSort options={SORT_OPTIONS} />
                </Stack>

                <Grid container spacing={3}>
                    {postData.map((post, index) => (
                        <BlogPostCard key={post.id} post={post} index={index} />
                    ))}
                    {!resdata &&
                        postData.length > 0 &&
                        [1, 2, 3, 4, 5, 6].map((key) => (
                            <Grid item xs={12} sm={6} md={3} key={key}>
                                <MuiSkeleton variant="rectangular" height={250} width={250} />
                            </Grid>
                        ))}
                </Grid>
                <div className="latest-indicator" />
            </Container>
        </Page>
    );
}
