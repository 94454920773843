import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import settingFill from '@iconify/icons-eva/settings-fill';
import editFill from '@iconify/icons-eva/edit-2-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
    {
        title: 'dashboard',
        path: '/dashboard/app',
        icon: getIcon(pieChart2Fill)
    },
    {
        title: 'blog',
        path: '/dashboard/blog',
        icon: getIcon(fileTextFill),
        children: [
            {
                title: 'List',
                path: '/dashboard/blog/list',
                icon: getIcon(fileTextFill)
            },
            {
                title: 'Buat Baru',
                path: '/dashboard/blog/new',
                icon: getIcon(editFill)
            },
            {
                title: 'Kategori',
                path: '/dashboard/blog/category',
                icon: getIcon(editFill)
            }
        ]
    },
    {
        title: 'Konten Statis',
        path: '/dashboard/contet',
        icon: getIcon(fileTextFill),
        children: [
            {
                title: 'List',
                path: '/dashboard/content/list',
                icon: getIcon(fileTextFill)
            },
            {
                title: 'Buat Baru',
                path: '/dashboard/content/new',
                icon: getIcon(editFill)
            }
        ]
    },
    {
        title: 'Pengaturan',
        path: '/dashboard/config',
        icon: getIcon(settingFill),
        children: [
            {
                title: 'Banner',
                path: '/dashboard/config/home/banner'
            },
            {
                title: 'Iklan',
                path: '/dashboard/config/ads'
            }
        ]
    }
];

export default sidebarConfig;
