/* eslint-disable react/prop-types */
import React from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import styled from '@mui/material/styles/styled';
import Field from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

const Container = styled(Card)(() => ({
    padding: 20,
    borderRadius: 5
}));

const Input = styled(Field)(() => ({
    marginBottom: 30
}));

const SwitchBox = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 30,
    padding: 5
}));

const FormAction = (props) => {
    const { formik, handleSubmit, handleDelete, isEdit } = props;

    return (
        <Grid container>
            <Grid item xs={12}>
                <Container>
                    <SwitchBox>
                        <Typography>Publish</Typography>
                        <Switch
                            checked={formik.values.published}
                            onChange={(e) => formik.setFieldValue('published', e.target.checked)}
                        />
                    </SwitchBox>
                    <Input
                        value={formik.values.meta_title}
                        onChange={formik.handleChange}
                        error={formik.errors.meta_title}
                        label="Meta title"
                        name="meta_title"
                        fullWidth
                    />
                    {/* <Input label="Meta Deskripsi" name="desc" fullWidth multiline rows={2} /> */}
                    <Grid container spacing={2}>
                        <Grid xs={6} item>
                            <Button variant="outlined" fullWidth color="inherit">
                                Pratinjau
                            </Button>
                        </Grid>
                        <Grid xs={6} item>
                            <Button
                                variant="contained"
                                fullWidth
                                color="primary"
                                onClick={handleSubmit}
                            >
                                Post
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Grid item xs={12}>
                {isEdit && (
                    <Container sx={{ mt: 3 }}>
                        <Button variant="text" fullWidth color="primary" onClick={handleDelete}>
                            Hapus Kontent
                        </Button>
                    </Container>
                )}
            </Grid>
        </Grid>
    );
};

export default FormAction;
