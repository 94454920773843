import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login/index';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import Blog from './pages/Blog/index';
import NewBlog from './pages/NewBlog/index';
import EditBlog from './pages/EditBlog/index';
import User from './pages/User';
import NotFound from './pages/Page404';
import HomeBanner from './pages/ConfigHome/Banner';
import BlogCategory from './pages/Category';

import Content from './pages/Content/index';
import NewContent from './pages/NewContent/index';
import EditContent from './pages/EditContent/index';

import AdsConfig from './pages/Ads';

// ----------------------------------------------------------------------

export default function Router() {
    return useRoutes([
        {
            path: '/dashboard',
            element: <DashboardLayout />,
            children: [
                { element: <Navigate to="/dashboard/app" replace /> },
                { path: 'app', element: <DashboardApp /> },
                { path: 'user', element: <User /> },
                { path: 'products', element: <Products /> },
                {
                    path: 'blog',
                    element: <Navigate to="/dashboard/blog/list" replace />
                },
                {
                    path: 'blog/list',
                    element: <Blog />
                },
                {
                    path: 'blog/category',
                    element: <BlogCategory />
                },
                {
                    path: 'blog/new',
                    element: <NewBlog />
                },
                {
                    path: 'blog/edit/:slug',
                    element: <EditBlog />
                },
                {
                    path: 'config/home/banner',
                    element: <HomeBanner />
                },
                {
                    path: 'config/ads',
                    element: <AdsConfig />
                },
                {
                    path: 'content',
                    element: <Navigate to="/dashboard/content/list" replace />
                },
                {
                    path: 'content/list',
                    element: <Content />
                },
                {
                    path: 'content/new',
                    element: <NewContent />
                },
                {
                    path: 'content/edit/:slug',
                    element: <EditContent />
                }
            ]
        },
        {
            path: '/',
            element: <LogoOnlyLayout />,
            children: [
                { path: 'login', element: <Login /> },
                { path: 'register', element: <Register /> },
                { path: '404', element: <NotFound /> },
                { path: '/', element: <Navigate to="/dashboard" /> },
                { path: '*', element: <Navigate to="/404" /> }
            ]
        },
        { path: '*', element: <Navigate to="/404" replace /> }
    ]);
}
