/* eslint-disable react-hooks/exhaustive-deps */
// material
import { Grid, Container, Typography, IconButton } from '@mui/material';
import styled from '@mui/material/styles/styled';
import { Icon } from '@iconify/react';
import backIcon from '@iconify/icons-ant-design/arrow-left';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import useSWR from 'swr';
import MuiAlert from '@mui/material/Alert';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import React from 'react';
import cookies from 'js-cookie';

// components
import Page from '../../components/Page';
import FormContent from '../NewContent/components/FormContent';
import FormAction from '../NewContent/components/FormAction';
import { cookiesConfig } from '../../config';
import uploadCloudinary from '../../utils/uploadCloudinary';
import AlertConfrimation from '../../components/AlertConfirmation';

// ----------------------------------------------------------------------

const Label = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: 150,
    marginBottom: 30,
    '& .backIcon': {
        marginRight: 10
    }
}));

export default function NewBlog() {
    const navigate = useNavigate();
    const { slug } = useParams();
    const { state } = useLocation();
    const [defaultData, setDefaultData] = React.useState({});
    const token = cookies.get(cookiesConfig.authTokenKey);
    if (!token) {
        window.location.href = '/login';
    }

    const [openAlert, setOpenAlert] = React.useState(false);
    const [openAlertDelete, setOpenAlertDelete] = React.useState(false);

    const { data } = useSWR(`/content/s/${slug}`);

    const ValidationAddress = {
        title: Yup.string().required('Judul harus di isi'),
        content: Yup.string().min(100, 'minimal 100 karakter').required('Konten harus di isi'),
        short_desc: Yup.string()
            .min(50, 'Deskripsi singkat minimal 50 karakter')
            .max(160, 'Deskripsi singkat maksimal 160 karakter')
    };
    const formik = useFormik({
        initialValues: {
            title: '',
            meta_title: '',
            thumbnail: '',
            short_desc: '',
            video_url: '',
            published: 0
        },
        validationSchema: Yup.object().shape(ValidationAddress),
        onSubmit: async (values, { setSubmitting }) => {
            const baseUrl = process.env.REACT_APP_API_URL;
            window.openLoader(true);
            try {
                let thumbnailUrl = defaultData.thumbnail_url;
                if (values.thumbnail && values.thumbnail !== '') {
                    thumbnailUrl = values.thumbnail;
                    if (typeof values.thumbnail !== 'string') {
                        thumbnailUrl = await uploadCloudinary(values.thumbnail);
                    }
                }
                axios(`${baseUrl}/content/${defaultData.id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        Authorization: `Bearer ${token}`
                    },
                    data: {
                        title: values.title,
                        content: values.content,
                        meta_title: values.meta_title || values.title,
                        thumbnail_url: thumbnailUrl,
                        published: values.published ? 1 : 0,
                        video_url: values.video_url,
                        short_desc: values.short_desc
                    }
                })
                    .then(async () => {
                        setSubmitting(false);
                        window.toastMessage({
                            open: true,
                            saverity: 'success',
                            message: 'Postingan berhasil di simpan'
                        });
                        window.openLoader(false);
                        setTimeout(() => navigate('/dashboard/content'), 1000);
                    })
                    .catch((e) => {
                        console.log('gagal');
                        console.log(e);
                        setSubmitting(false);
                        window.openLoader(false);
                        window.toastMessage({
                            open: true,
                            saverity: 'error',
                            message: 'Postingan gagal di simpan'
                        });
                    });
            } catch (e) {
                console.log('gagal');
                console.log(e);
                setSubmitting(false);
                window.openLoader(false);
                window.toastMessage({
                    open: true,
                    saverity: 'error',
                    message: 'Postingan gagal di simpan'
                });
            }
        }
    });

    React.useEffect(() => {
        if (state && state.id) {
            setDefaultData(state);
            formik.setValues({
                ...formik.values,
                title: state.title,
                meta_title: state.meta_title,
                category: state.category,
                content: state.content,
                short_desc: state.short_desc,
                published: state.published,
                video_url: state.video_url
            });
        } else if (data && data.data) {
            setDefaultData(data.data);
            formik.setValues({
                ...formik.values,
                title: data.data.title,
                meta_title: data.data.meta_title,
                category: data.data.category,
                content: data.data.content,
                short_desc: data.data.short_desc,
                published: data.data.published,
                video_url: data.data.video_url
            });
        }
    }, [state]);

    const handleSubmit = () => setOpenAlert(true);
    const handleNext = () => {
        setOpenAlert(false);
        formik.handleSubmit();
    };

    const handleDelete = () => setOpenAlertDelete(true);
    const handleNextDelete = () => {
        const baseUrl = process.env.REACT_APP_API_URL;
        window.openLoader(true);
        axios(`${baseUrl}/content/${defaultData.id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
            .then(async () => {
                window.toastMessage({
                    open: true,
                    saverity: 'success',
                    message: 'Postingan berhasil di hapus'
                });
                window.openLoader(false);
                setTimeout(() => navigate('/dashboard/content'), 1000);
            })
            .catch((e) => {
                console.log('gagal');
                console.log(e);
                window.openLoader(false);
                window.toastMessage({
                    open: true,
                    saverity: 'error',
                    message: 'Postingan gagal di hapus'
                });
            });
    };

    if (formik.errors) console.log(formik.errors);

    if (defaultData && defaultData.id) {
        return (
            <Page title="Petanesia: Konten | Edit Konten">
                <AlertConfrimation
                    open={openAlert}
                    handleClose={() => setOpenAlert(false)}
                    message="Yakin akan menyimpan perubahan ?"
                    handleNext={handleNext}
                />
                <AlertConfrimation
                    open={openAlertDelete}
                    handleClose={() => openAlertDelete(false)}
                    message="Yakin akan meghapus konten ini ?"
                    handleNext={handleNextDelete}
                />
                <Container>
                    <Label>
                        <IconButton
                            size="small"
                            className="backIcon"
                            onClick={() => navigate('/dashboard/content')}
                        >
                            <Icon icon={backIcon} fontSize={18} />
                        </IconButton>
                        <Typography variant="h4" gutterBottom>
                            Buat Konten
                        </Typography>
                    </Label>
                    <Grid container spacing={3}>
                        {Object.keys(formik.errors).length > 0 && (
                            <Grid item xs={12}>
                                <MuiAlert elevation={0} variant="filled" severity="error">
                                    Ada field yang kosong silahkan cek kembali
                                </MuiAlert>
                            </Grid>
                        )}
                        <Grid item xs={12} sm={12} md={8}>
                            <FormContent
                                formik={formik}
                                defaultPhotoUrl={defaultData.thumbnail_url}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <FormAction
                                formik={formik}
                                handleDelete={handleDelete}
                                handleSubmit={handleSubmit}
                                isEdit={defaultData && defaultData.id !== null}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Page>
        );
    }

    return null;
}
