/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import Upload from '../../../components/UploadImage';
import uploadCloudinary from '../../../utils/uploadCloudinary';

ModalBanner.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    dataEdit: PropTypes.object,
    token: PropTypes.string.isRequired,
    refetch: PropTypes.func
};

export default function ModalBanner(props) {
    const { open, handleClose: onClose, dataEdit, token, refetch } = props;
    const ValidationAddress = {
        image: !dataEdit && Yup.mixed().required('Silahkan upload gambar')
    };

    const [display, setDisplay] = React.useState('block');

    const formik = useFormik({
        initialValues: {
            image: '',
            title: '',
            desc: '',
            link_url: ''
        },
        validationSchema: Yup.object().shape(ValidationAddress),
        onSubmit: async (values, { setSubmitting }) => {
            const baseUrl = process.env.REACT_APP_API_URL;
            window.openLoader(true);
            setDisplay('none');
            try {
                let imageUrl;
                if (dataEdit && dataEdit.imgUrl) {
                    imageUrl = dataEdit.imgUrl;
                }
                if (values.image && values.image !== '') {
                    imageUrl = await uploadCloudinary(values.image);
                }

                const url =
                    dataEdit && dataEdit.id
                        ? `${baseUrl}/banner/${dataEdit.id}`
                        : `${baseUrl}/banner`;
                const method = dataEdit && dataEdit.id ? 'PUT' : 'POST';
                let data = {
                    title: values.title,
                    desc: values.desc,
                    link_url: values.link_url,
                    img_url: imageUrl
                };

                if (dataEdit && dataEdit.id) {
                    data = {
                        id: dataEdit.id,
                        ...data
                    };
                }

                axios(url, {
                    method,
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        Authorization: `Bearer ${token}`
                    },
                    data
                })
                    .then(async () => {
                        setSubmitting(false);
                        window.toastMessage({
                            open: true,
                            saverity: 'success',
                            message: 'Banner berhasil di simpan'
                        });
                        window.openLoader(false);
                        setDisplay('block');
                        refetch();
                        setTimeout(() => handleClose(), 1000);
                    })
                    .catch((e) => {
                        console.log('gagal');
                        console.log(e);
                        setSubmitting(false);
                        window.openLoader(false);
                        setDisplay('block');
                        window.toastMessage({
                            open: true,
                            saverity: 'error',
                            message: 'Banner gagal di simpan'
                        });
                    });
            } catch (e) {
                console.log('gagal');
                console.log(e);
                setSubmitting(false);
                setDisplay('block');
                window.toastMessage({
                    open: true,
                    saverity: 'error',
                    message: 'Banner gagal di simpan'
                });
            }
        }
    });

    const setValueImage = (fileLogo) => {
        formik.setFieldValue('image', fileLogo);
    };

    const handleClose = (event, reason) => {
        if (!reason && reason !== 'backdropClick') {
            formik.resetForm();
            onClose();
        }
    };

    React.useEffect(() => {
        if (dataEdit && dataEdit.id) {
            formik.setValues({
                ...formik.values,
                title: dataEdit.title || '',
                desc: dataEdit.desc || '',
                link_url: dataEdit.link_url || ''
            });
        }
    }, [dataEdit]);

    return (
        <Dialog open={open} onClose={handleClose} disableEscapeKeyDown sx={{ display }}>
            <DialogTitle>Tambah banner</DialogTitle>
            <DialogContent>
                <Upload
                    defaultPhotoUrl={dataEdit ? dataEdit.img_url : ''}
                    maxSize={3000000}
                    maxWidth={1900}
                    maxHeight={1200}
                    handleDrop={setValueImage}
                    label="Upload Banner"
                />
                <TextField
                    sx={{ mt: 5 }}
                    autoFocus
                    margin="dense"
                    id="title"
                    label="Title"
                    fullWidth
                    variant="outlined"
                    onChange={formik.handleChange}
                    value={formik.values.title}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="desc"
                    label="Deskripsi"
                    fullWidth
                    variant="outlined"
                    onChange={formik.handleChange}
                    value={formik.values.desc}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="link_url"
                    label="Link Gambar"
                    fullWidth
                    variant="outlined"
                    onChange={formik.handleChange}
                    value={formik.values.link_url}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Batal</Button>
                <Button onClick={formik.handleSubmit}>Simpan Banner</Button>
            </DialogActions>
        </Dialog>
    );
}
