import MuiBackdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const Backdrop = styled(MuiBackdrop)(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    '@keyframes': {
        '0%': { transform: 'rotate(0deg)' },
        '100%': { transform: 'rotate(360deg)' }
    },
    '& .loaderBackdrop': {
        width: 60,
        height: 60,
        animationName: '$backdrop',
        animation: '1.5s linear infinite'
    },
    '& .loaderGif': {
        width: 60,
        height: 50
    }
}));

const loaderImage = '/static/img/loader.svg';

const CustomBackdrop = ({ open }) => {
    let image;
    const extention = loaderImage.split('.').pop();
    if (extention === '' || !extention) {
        image = <img src={loaderImage} alt={loaderImage} />;
    } else if (extention === 'png' || extention === 'jpg' || extention === 'jpeg') {
        image = <img className="loaderBackdrop" src={loaderImage} alt={loaderImage} />;
    } else {
        image = <img src={loaderImage} alt={loaderImage} className="loaderGif" />;
    }
    return (
        <Backdrop open={open}>
            {!loaderImage || loaderImage === '' ? <CircularProgress /> : image}
        </Backdrop>
    );
};

CustomBackdrop.propTypes = {
    open: PropTypes.bool
};

CustomBackdrop.defaultProps = {
    open: false
};

export default CustomBackdrop;
