/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
import Typography from '@mui/material/Typography';
import { useDropzone } from 'react-dropzone';
import React, { useCallback, useMemo } from 'react';
import propTypes from 'prop-types';
import { Icon } from '@iconify/react';
import uploadIcon from '@iconify/icons-ant-design/upload';

const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const UploadImage = ({
    acceptedFile,
    maxSize,
    multiple,
    handleDrop,
    getBase64,
    dropValue,
    setValue,
    noStyle = false,
    maxWidth,
    maxHeight,
    label,
    defaultPhotoUrl
}) => {
    const [dropFile, setDropFile] = React.useState(dropValue);
    const checkImage = async (files) => {
        const promises = [];
        for (let index = 0; index < files.length; index += 1) {
            const file = files[index];
            const promise = new Promise((resolve) => {
                const image = new Image();
                image.onload = () => {
                    file.width = image.width;
                    file.height = image.height;
                    resolve(file);
                };
                const url = URL.createObjectURL(file);
                image.src = url;
            });
            promises.push(promise);
        }

        const dataFiles = await Promise.all(promises);
        let errorImage;
        for (let index = 0; index < dataFiles.length; index += 1) {
            const file = files[index];
            if (file.type && file.type.match(/image/) && maxWidth && maxHeight) {
                if (file.width >= maxWidth || file.height >= maxHeight) {
                    errorImage = `Maksial ukuran gambar ${maxWidth}X${maxHeight} pixel`;
                }
            }
        }

        return errorImage;
    };
    const onDrop = useCallback(async (files) => {
        if (files && files.length > 0) {
            const errorImage = await checkImage(files);
            if (!errorImage) {
                if (multiple) {
                    setDropFile([...dropFile, ...files]);
                    if (setValue) setValue([...dropFile, ...files]);
                } else {
                    setDropFile([files[0]]);
                    if (setValue) setValue([files[0]]);
                }
                handleDrop(files[0]);
            } else {
                alert(errorImage);
            }
        }
        // Do something with the files
    }, []);

    const [images, setImages] = React.useState([]);

    const onDropAccepted = async (files) => {
        // eslint-disable-next-line array-callback-return
        setImages(
            files.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })
            )
        );
        let filebase64 = [];
        for (let ind = 0; ind < files.length; ind += 1) {
            // eslint-disable-next-line no-await-in-loop
            const baseCode = await toBase64(files[ind]);
            if (baseCode) {
                filebase64 = [
                    ...filebase64,
                    {
                        baseCode,
                        file: files[ind]
                    }
                ];
            }
        }

        const errorImage = await checkImage(files);
        if (!errorImage) {
            getBase64(filebase64);
            handleDrop(files[0]);
        }
    };

    React.useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            images.forEach((file) => URL.revokeObjectURL(file.preview));
        },
        [images]
    );

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        onDrop,
        accept: acceptedFile,
        onDropAccepted,
        onDropRejected: (ee) => console.log(ee),
        maxSize
    });

    const baseStyle = {
        width: '100%',
        height: 150,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        breturnWidth: 2,
        breturnRadius: 2,
        breturnColor: '#eeeeee',
        breturnStyle: 'dashed',
        backgroundColor: '#fff',
        color: '#f72585',
        border: `1px solid #f0f0f0`,
        borderRadius: 5,
        opacity: 1,
        outline: 'none',
        transition: 'breturn .24s ease-in-out',
        cursor: 'pointer'
    };

    const activeStyle = {
        breturnColor: '#2196f3'
    };

    const acceptStyle = {
        breturnColor: '#00e676'
    };

    const rejectStyle = {
        breturnColor: '#ff1744'
    };

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {})
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    return (
        <div className="column">
            <div {...getRootProps({ style: noStyle ? {} : style })}>
                <input {...getInputProps()} />
                <div className="column center">
                    {images && images.length > 0 ? (
                        <div className="center imgbox">
                            <img src={images[0].preview} alt="img" className="img-upload" />
                        </div>
                    ) : (
                        <>
                            {defaultPhotoUrl && defaultPhotoUrl !== '' ? (
                                <div className="center imgbox">
                                    <img src={defaultPhotoUrl} alt="img" className="img-upload" />
                                    <div className="hover-label">Ganti gambar</div>
                                </div>
                            ) : (
                                <>
                                    <div className="center icon-upload">
                                        <Icon icon={uploadIcon} fontSize={18} />
                                    </div>
                                    <Typography variant="p" type="bold">
                                        {label}
                                    </Typography>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
            <style jsx>
                {`
                    .center {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }

                    .icon-upload {
                        width: 30px;
                        height: 30px;
                        border: 1px solid #bd1f93;
                        color: #bd1f93;
                        padding: 4px;
                        border-radius: 50%;
                    }

                    .imgbox {
                        width: 100%;
                        overflow: hidden;
                        max-height: 150px;
                        position: relative;
                        border: 1px solid black;
                    }

                    .hover-label {
                        display: none;
                        position: absolute;
                        top: cacl(50% - 20px);
                        text-align: center;
                        z-index: 999;
                        font-size: 16px;
                        padding: 10px;
                        border-radius: 10px;
                        background-color: rgba(255, 64, 79, 0.66);
                        color: #fff;
                    }

                    .img-upload:hover + .hover-label {
                        display: block;
                    }

                    .hover-label:hover {
                        display: block;
                    }

                    .img-upload {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                `}
            </style>
        </div>
    );
};

UploadImage.propTypes = {
    label: propTypes.string,
    title: propTypes.string,
    showListFile: propTypes.bool,
    acceptedFile: propTypes.string,
    maxSize: propTypes.number,
    multiple: propTypes.bool,
    handleDrop: propTypes.func,
    getBase64: propTypes.func,
    error: propTypes.any,
    dropValue: propTypes.array,
    value: propTypes.array,
    setValue: propTypes.func,
    defaultPhotoUrl: propTypes.string
};

UploadImage.defaultProps = {
    defaultPhotoUrl: '',
    label: '',
    title: '',
    showListFile: true,
    acceptedFile: 'image/*',
    maxSize: 2000000,
    multiple: true,
    handleDrop: () => {},
    getBase64: () => {},
    error: false,
    dropValue: [],
    value: [],
    setValue: () => {}
};

export default UploadImage;
