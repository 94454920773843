export const defaultTitle = 'Petanesia | Portal';
export const cookiesConfig = {
    expired: 36,
    authTokenKey: '_authtokey',
    userData: '_userData'
};

export default {
    defaultTitle
};
