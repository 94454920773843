// material
import { Container, Typography, IconButton, Stack, Button } from '@mui/material';
import styled from '@mui/material/styles/styled';
import { Icon } from '@iconify/react';
import backIcon from '@iconify/icons-ant-design/arrow-left';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import React from 'react';
import cookies from 'js-cookie';

// components
import Page from '../../components/Page';
import { cookiesConfig } from '../../config';
import AlertConfrimation from '../../components/AlertConfirmation';
import TableCategory from './TableCategory';
import Skeleton from './SkeletonTable';
import ModalCategory from './ModalCategory';

// ----------------------------------------------------------------------

const Label = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: 250,
    marginBottom: 30,
    '& .backIcon': {
        marginRight: 10
    }
}));

export default function BlogCategory() {
    const navigate = useNavigate();
    const token = cookies.get(cookiesConfig.authTokenKey);
    if (!token) {
        window.location.href = '/login';
    }

    const [dataCategory, setDataCategory] = React.useState([]);

    const { data, error, mutate } = useSWR('/category');

    React.useEffect(() => {
        if (data && data.data && data.data.data) {
            setDataCategory(data.data.data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const [openAlert, setOpenAlert] = React.useState(false);
    const [openBanner, setOpenBanner] = React.useState(false);
    const [dataEdit, setDataEdit] = React.useState(null);

    const handleNext = () => {
        setOpenAlert(false);
    };

    const handleEdit = (param) => {
        setDataEdit(param);
        console.log(param);
        setOpenBanner(true);
    };

    const handleClosePopup = () => {
        setDataEdit(null);
        setOpenBanner(false);
    };

    return (
        <Page title="Petanesia: Blog | Kategori">
            <AlertConfrimation
                open={openAlert}
                handleClose={() => setOpenAlert(false)}
                message="Yakin akan menyimpan perubahan ?"
                handleNext={handleNext}
            />
            {openBanner && (
                <ModalCategory
                    open={openBanner}
                    handleClose={handleClosePopup}
                    dataEdit={dataEdit}
                    token={token}
                    refetch={() => mutate()}
                />
            )}
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Label>
                        <IconButton
                            size="small"
                            className="backIcon"
                            onClick={() => navigate('/dashboard/blog')}
                        >
                            <Icon icon={backIcon} fontSize={18} />
                        </IconButton>
                        <Typography variant="h4" gutterBottom>
                            Konten Kategori
                        </Typography>
                    </Label>
                    <Button
                        variant="contained"
                        onClick={() => setOpenBanner(true)}
                        startIcon={<Icon icon={plusFill} />}
                        disabled={!token}
                    >
                        Tambah Kategori
                    </Button>
                </Stack>
                {error || !data ? (
                    <Skeleton />
                ) : (
                    <TableCategory
                        data={dataCategory}
                        refetch={() => mutate()}
                        handleEdit={handleEdit}
                        token={token}
                    />
                )}
            </Container>
        </Page>
    );
}
