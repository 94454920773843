/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

ModalKategori.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    dataEdit: PropTypes.object,
    token: PropTypes.string.isRequired,
    refetch: PropTypes.func
};

export default function ModalKategori(props) {
    const { open, handleClose: onClose, dataEdit, token, refetch } = props;
    const ValidationAddress = {
        title: Yup.string().required('Nama kategori harus di isi'),
        content: Yup.string()
            .min(5, 'Minimal 5 karakter')
            .required('Deskripsi kategori harus di isi')
    };

    const [display, setDisplay] = React.useState('block');

    const formik = useFormik({
        initialValues: {
            title: '',
            content: ''
        },
        validationSchema: Yup.object().shape(ValidationAddress),
        onSubmit: async (values, { setSubmitting }) => {
            const baseUrl = process.env.REACT_APP_API_URL;
            window.openLoader(true);
            setDisplay('none');
            try {
                const method = dataEdit && dataEdit.id ? 'PUT' : 'POST';
                let data = {
                    title: values.title,
                    content: values.content
                };

                if (dataEdit && dataEdit.id) {
                    data = {
                        id: dataEdit.id,
                        ...data
                    };
                }

                const url =
                    dataEdit && dataEdit.id
                        ? `${baseUrl}/category/${dataEdit.id}`
                        : `${baseUrl}/category`;

                axios(url, {
                    method,
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        Authorization: `Bearer ${token}`
                    },
                    data
                })
                    .then(async () => {
                        setSubmitting(false);
                        window.toastMessage({
                            open: true,
                            saverity: 'success',
                            message: 'Kategori berhasil di simpan'
                        });
                        window.openLoader(false);
                        setDisplay('block');
                        refetch();
                        setTimeout(() => handleClose(), 1000);
                    })
                    .catch((e) => {
                        console.log('gagal');
                        console.log(e);
                        setSubmitting(false);
                        window.openLoader(false);
                        setDisplay('block');
                        window.toastMessage({
                            open: true,
                            saverity: 'error',
                            message: 'Kategori gagal di simpan'
                        });
                    });
            } catch (e) {
                console.log('gagal');
                console.log(e);
                setSubmitting(false);
                setDisplay('block');
                window.toastMessage({
                    open: true,
                    saverity: 'error',
                    message: 'Kategori gagal di simpan'
                });
            }
        }
    });

    const handleClose = (event, reason) => {
        if (!reason && reason !== 'backdropClick') {
            formik.resetForm();
            onClose();
        }
    };

    React.useEffect(() => {
        if (dataEdit && dataEdit.id) {
            formik.setValues({
                ...formik.values,
                title: dataEdit.title || '',
                content: dataEdit.content || ''
            });
        } else {
            formik.setValues({
                title: '',
                content: ''
            });
        }
    }, [dataEdit]);

    return (
        <Dialog open={open} onClose={handleClose} disableEscapeKeyDown sx={{ display }}>
            <DialogTitle>Tambah Kategori</DialogTitle>
            <DialogContent>
                <TextField
                    sx={{ mt: 2 }}
                    autoFocus
                    margin="dense"
                    id="title"
                    name="title"
                    label="Nama Kategori"
                    fullWidth
                    variant="outlined"
                    onChange={formik.handleChange}
                    value={formik.values.title}
                    error={formik.errors.title}
                    helperText={formik.errors.title || ''}
                />
                <TextField
                    sx={{ mt: 5 }}
                    autoFocus
                    margin="dense"
                    id="content"
                    name="content"
                    label="Deskripsi"
                    fullWidth
                    variant="outlined"
                    onChange={formik.handleChange}
                    value={formik.values.content}
                    error={formik.errors.content}
                    helperText={formik.errors.content || ''}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Batal</Button>
                <Button onClick={formik.handleSubmit}>Simpan Kategori</Button>
            </DialogActions>
        </Dialog>
    );
}
