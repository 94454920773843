/* eslint-disable react/prop-types */
import React from 'react';
import Card from '@mui/material/Card';
import Field from '@mui/material/TextField';
import styled from '@mui/material/styles/styled';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Upload from './UploadCover';

const Container = styled(Card)(() => ({
    padding: 20,
    height: '100%',
    minHeight: '50vh',
    borderRadius: 5,
    '& .ql-editor': {
        minHeight: '18em'
    }
}));

const Input = styled(Field)(() => ({
    marginBottom: 30
}));

const Editor = styled(ReactQuill)(() => ({
    marginBottom: 30
}));

const FormInput = (props) => {
    const { formik, defaultPhotoUrl } = props;
    const formatEditor = [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'video'
    ];
    const editorModules = {
        toolbar: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            ['link', 'image', 'video'],
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false
        }
    };

    const setValueImage = (fileLogo) => {
        formik.setFieldValue('thumbnail', fileLogo);
    };

    let errorThumbnail = false;
    if (formik.errors.thumbnail) {
        errorThumbnail = true;
    } else {
        errorThumbnail = false;
    }

    return (
        <Container>
            <Input
                label="Judul"
                name="title"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.title}
                error={formik.errors.title}
            />
            <Input
                multiline
                rows={2}
                maxRows={2}
                label="Deskripsi Singkat"
                name="short_desc"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.short_desc}
                error={formik.errors.short_desc}
            />
            <Editor
                theme="snow"
                placeholder="Tulis konten disini"
                formats={formatEditor}
                modules={editorModules}
                value={formik.values.content}
                onChange={(e) => formik.setFieldValue('content', e)}
                style={{ border: formik.errors.content ? '1px solid red' : '' }}
            />
            <Upload
                defaultPhotoUrl={defaultPhotoUrl}
                maxSize={3000000}
                maxWidth={1900}
                maxHeight={1200}
                handleDrop={setValueImage}
                error={errorThumbnail}
            />
            {(!formik.values.thumbnail ||
                formik.values.thumbnail === '' ||
                typeof formik.values.thumbnail === 'string') && (
                <Input
                    sx={{ mt: 4 }}
                    placeholder="Isi URL Gambar (Jika tidak upload gambar manual)"
                    label="URL Gambar (Jika tidak upload)"
                    name="thumbnail"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.thumbnail}
                    error={formik.errors.thumbnail}
                />
            )}
            <Input
                sx={{ mt: 1 }}
                placeholder="Isi embed url dari youtube atau cloud"
                label="URL Video"
                name="video_url"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.video_url}
                error={formik.errors.video_url}
            />
        </Container>
    );
};

export default FormInput;
