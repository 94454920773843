import { useState } from 'react';
// material
import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer as MuiTableContainer,
    Switch
} from '@mui/material';
import styled from '@mui/material/styles/styled';
import PropTypes from 'prop-types';
import axios from 'axios';
// components
import Scrollbar from '../../components/Scrollbar';
//

import ListHead from './ListHead';
import MoreMenu from './MoreMenu';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'image_url', label: 'Image', alignRight: false },
    { id: 'key', label: 'Key', alignRight: false },
    { id: 'link_url', label: 'URL', alignRight: false },
    { id: 'order', label: 'Urutan', alignRight: false },
    { id: 'start_time', label: 'Jam Mulai', alignRight: false },
    { id: 'end_time', label: 'Jam Berakhir', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: '' }
];

// ----------------------------------------------------------------------

TableAds.propTypes = {
    data: PropTypes.array.isRequired,
    handleEdit: PropTypes.func,
    refetch: PropTypes.func,
    token: PropTypes.string
};

const TableContainer = styled(MuiTableContainer)(() => ({
    '& img': {
        width: '100%',
        height: 'auto',
        maxWidth: 75,
        margin: 10
    }
}));

export default function TableAds({ data = [], handleEdit, refetch, token }) {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const emptyRows = data.length < 1;

    const handleDelete = ({ id }) => {
        const baseUrl = process.env.REACT_APP_API_URL;
        window.openLoader(true);
        const method = 'DELETE';

        const url = `${baseUrl}/ads/${id}`;

        axios(url, {
            method,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            },
            data
        })
            .then(async () => {
                window.toastMessage({
                    open: true,
                    saverity: 'success',
                    message: 'Iklan berhasil di hapus'
                });
                window.openLoader(false);
                refetch();
            })
            .catch(() => {
                window.openLoader(false);
                window.toastMessage({
                    open: true,
                    saverity: 'error',
                    message: 'Iklan gagal di hapus'
                });
            });
    };

    return (
        <Card>
            <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                        <ListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={data.length}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {data.map((row, keyIdx) => {
                                // eslint-disable-next-line camelcase
                                const {
                                    id,
                                    key,
                                    image_url: imgUrl,
                                    link_url: linkUrl,
                                    order,
                                    start_time: startTime,
                                    end_time: endTime,
                                    status
                                } = row;
                                return (
                                    <TableRow hover key={id} tabIndex={-1} role="checkbox">
                                        <TableCell padding="checkbox">
                                            <Typography align="center">{keyIdx + 1}</Typography>
                                        </TableCell>
                                        <TableCell component="th" scope="row" padding="none">
                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                <img src={imgUrl} alt={linkUrl} />
                                            </Stack>
                                        </TableCell>
                                        <TableCell align="left">{key}</TableCell>
                                        <TableCell align="left">{linkUrl}</TableCell>
                                        <TableCell align="left">{order}</TableCell>
                                        <TableCell align="left">{startTime}</TableCell>
                                        <TableCell align="left">{endTime}</TableCell>
                                        <TableCell align="left">
                                            <Switch checked={status === 1} />
                                        </TableCell>
                                        <TableCell align="right">
                                            <MoreMenu
                                                handleEdit={() => handleEdit(row)}
                                                handleDelete={() => handleDelete(row)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Scrollbar>
        </Card>
    );
}
