/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import { Checkbox, FormControlLabel } from '@mui/material';
import Upload from '../../components/UploadImage';

ModalAds.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    dataEdit: PropTypes.object,
    token: PropTypes.string.isRequired,
    refetch: PropTypes.func
};

export default function ModalAds(props) {
    const { open, handleClose: onClose, dataEdit, token, refetch } = props;
    const ValidationAddress = {
        image: !dataEdit && Yup.mixed().required('Silahkan upload gambar'),
        key: Yup.string().required('Key harus di isi!'),
        link_url: Yup.string().url(
            'URL yang di inputkan salah. Contoh benar: https://petanesianews.com'
        ),
        order: Yup.number('Order harus berupa angka'),
        start_time: Yup.number('Jam mulai harus berupa angka'),
        end_time: Yup.number('Jam berakhir harus berupa angka')
    };

    const [display, setDisplay] = React.useState('block');

    const formik = useFormik({
        initialValues: {
            image: '',
            link_url: '',
            key: '',
            order: '',
            status: '',
            start_time: '',
            end_time: ''
        },
        validationSchema: Yup.object().shape(ValidationAddress),
        onSubmit: async (values, { setSubmitting }) => {
            const baseUrl = process.env.REACT_APP_API_URL;
            // window.openLoader(true);
            // setDisplay('none');
            try {
                const url =
                    dataEdit && dataEdit.id ? `${baseUrl}/ads/${dataEdit.id}` : `${baseUrl}/ads`;
                const method = 'POST';
                const formData = new FormData();
                formData.append('link_url', values.link_url);
                formData.append('key', values.key);
                formData.append('order', parseInt(values.order, 10));
                formData.append('status', values.status ? 1 : 0);
                formData.append('start_time', parseInt(values.start_time, 10));
                formData.append('end_time', parseInt(values.end_time, 10));
                if (values.image && values.image !== '') {
                    formData.append('image', values.image);
                }

                // // eslint-disable-next-line no-restricted-syntax
                // for (const pair of formData.entries()) {
                //     console.log(`${pair[0]}, ${pair[1]}`);
                // }

                axios(url, {
                    method,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`
                    },
                    data: formData,
                    params: {
                        _method: dataEdit && dataEdit.id ? 'PUT' : 'POST'
                    }
                })
                    .then(async () => {
                        setSubmitting(false);
                        window.toastMessage({
                            open: true,
                            saverity: 'success',
                            message: 'Iklan berhasil di simpan'
                        });
                        window.openLoader(false);
                        setDisplay('block');
                        refetch();
                        setTimeout(() => handleClose(), 1000);
                    })
                    .catch((e) => {
                        console.log('gagal');
                        console.log(e);
                        setSubmitting(false);
                        window.openLoader(false);
                        setDisplay('block');
                        window.toastMessage({
                            open: true,
                            saverity: 'error',
                            message: 'Iklan gagal di simpan'
                        });
                    });
            } catch (e) {
                console.log('gagal');
                console.log(e);
                setSubmitting(false);
                setDisplay('block');
                window.toastMessage({
                    open: true,
                    saverity: 'error',
                    message: 'Banner gagal di simpan'
                });
            }
        }
    });

    const setValueImage = (fileLogo) => {
        formik.setFieldValue('image', fileLogo);
    };

    const handleClose = (event, reason) => {
        if (!reason && reason !== 'backdropClick') {
            formik.resetForm();
            onClose();
        }
    };

    React.useEffect(() => {
        if (dataEdit && dataEdit.id) {
            formik.setValues({
                ...formik.values,
                link_url: dataEdit.link_url || '',
                key: dataEdit.key || '',
                order: dataEdit.order || 0,
                start_time: dataEdit.start_time || 0,
                end_time: dataEdit.end_time || 0,
                status: dataEdit.status
            });
        }
    }, [dataEdit]);

    return (
        <Dialog open={open} onClose={handleClose} disableEscapeKeyDown sx={{ display }}>
            <DialogTitle>Tambah iklan</DialogTitle>
            <DialogContent>
                <Upload
                    defaultPhotoUrl={dataEdit ? dataEdit.image_url : ''}
                    maxSize={2000000}
                    maxWidth={1900}
                    maxHeight={1200}
                    handleDrop={setValueImage}
                    label="Upload Gambar Iklan"
                />
                <TextField
                    sx={{ mt: 5 }}
                    autoFocus
                    margin="dense"
                    id="key"
                    name="key"
                    label="Key"
                    fullWidth
                    variant="outlined"
                    onChange={formik.handleChange}
                    value={formik.values.key}
                    error={formik.errors.key && formik.touched.key}
                    helperText={
                        formik.errors.key &&
                        formik.touched.key && (
                            <span style={{ marginBottom: 12 }}>{formik.errors.key}</span>
                        )
                    }
                />
                <TextField
                    sx={{ mt: 3 }}
                    autoFocus
                    margin="dense"
                    id="link_url"
                    name="link_url"
                    label="URL"
                    fullWidth
                    variant="outlined"
                    onChange={formik.handleChange}
                    value={formik.values.link_url}
                    error={formik.errors.link_url && formik.touched.link_url}
                    helperText={
                        formik.errors.link_url &&
                        formik.touched.link_url && (
                            <span style={{ marginBottom: 12 }}>{formik.errors.link_url}</span>
                        )
                    }
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="order"
                    name="order"
                    label="Urutan (0-99)"
                    fullWidth
                    variant="outlined"
                    onChange={formik.handleChange}
                    value={formik.values.order}
                    error={formik.errors.order && formik.touched.order}
                    helperText={
                        formik.errors.order &&
                        formik.touched.order && (
                            <span style={{ marginBottom: 12 }}>{formik.errors.order}</span>
                        )
                    }
                />
                <TextField
                    sx={{ mt: 3 }}
                    autoFocus
                    margin="dense"
                    id="start_time"
                    name="start_time"
                    label="Jam Mulai (0 - 23)"
                    fullWidth
                    variant="outlined"
                    onChange={formik.handleChange}
                    value={formik.values.start_time}
                    error={formik.errors.start_time && formik.touched.start_time}
                    helperText={
                        formik.errors.start_time &&
                        formik.touched.start_time && (
                            <span style={{ marginBottom: 12 }}>{formik.errors.start_time}</span>
                        )
                    }
                />
                <TextField
                    sx={{ mt: 3 }}
                    autoFocus
                    margin="dense"
                    id="end_time"
                    name="end_time"
                    label="Jam Berakhir (1 - 24)"
                    fullWidth
                    variant="outlined"
                    onChange={formik.handleChange}
                    value={formik.values.end_time}
                    error={formik.errors.end_time && formik.touched.end_time}
                    helperText={
                        formik.errors.end_time &&
                        formik.touched.end_time && (
                            <span style={{ marginBottom: 12 }}>{formik.errors.end_time}</span>
                        )
                    }
                />
                <FormControlLabel
                    sx={{ mt: 1 }}
                    label="Status"
                    control={
                        <Checkbox
                            checked={formik.values.status}
                            onChange={() => formik.setFieldValue('status', !formik.values.status)}
                        />
                    }
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Batal</Button>
                <Button onClick={formik.handleSubmit}>Simpan Banner</Button>
            </DialogActions>
        </Dialog>
    );
}
