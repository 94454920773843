import React from 'react';
import { motion } from 'framer-motion';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Icon as Iconify } from '@iconify/react';
import infoIcon from '@iconify/icons-eva/info-outline';
import propTypes from 'prop-types';
import { MotionContainer, varBounceIn } from './animate';
import pallete from '../theme/palette';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
};

const AlertConfirmation = ({ open, message, Icon, handleClose, handleNext }) => (
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-alert"
        aria-describedby="modal-modal-description"
    >
        <MotionContainer initial="initial" open>
            <Box sx={style}>
                <motion.div
                    variants={varBounceIn}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {Icon ? (
                        <Icon />
                    ) : (
                        <Iconify
                            icon={infoIcon}
                            color={pallete.primary.light}
                            fontSize={50}
                            align="center"
                        />
                    )}
                    <Typography id="modal-alert" variant="h6" component="h2">
                        {message || 'Info'}
                    </Typography>
                </motion.div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        marginTop: 20
                    }}
                >
                    <Button variant="contained" sx={{ mr: 2 }} onClick={handleClose}>
                        Batal
                    </Button>
                    <Button variant="outlined" onClick={handleNext}>
                        Lanjutkan
                    </Button>
                </div>
            </Box>
        </MotionContainer>
    </Modal>
);

AlertConfirmation.propTypes = {
    open: propTypes.bool.isRequired,
    message: propTypes.string,
    Icon: propTypes.element,
    handleClose: propTypes.func.isRequired,
    handleNext: propTypes.func.isRequired
};

export default AlertConfirmation;
