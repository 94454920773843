import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import PropTypes from 'prop-types';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';

// ----------------------------------------------------------------------

import AlertConfrimation from '../../components/AlertConfirmation';

MoreMenu.propTypes = {
    handleEdit: PropTypes.func,
    handleDelete: PropTypes.func
};

MoreMenu.defaultProps = {
    handleEdit: () => {},
    handleDelete: () => {}
};

export default function MoreMenu({ handleEdit: onEdit, handleDelete: onDelete }) {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const [openAlert, setOpenAlert] = useState(false);

    const handleEdit = () => {
        setIsOpen(false);
        onEdit();
    };

    const handleDelete = () => {
        setOpenAlert(true);
        setIsOpen(false);
    };

    const handleNext = () => {
        setOpenAlert(false);
        onDelete();
    };

    return (
        <>
            <IconButton ref={ref} onClick={() => setIsOpen(true)}>
                <Icon icon={moreVerticalFill} width={20} height={20} />
            </IconButton>

            <AlertConfrimation
                open={openAlert}
                handleClose={() => setOpenAlert(false)}
                message="Yakin akan menghapus banner ini ?"
                handleNext={handleNext}
            />

            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: { width: 200, maxWidth: '100%' }
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MenuItem sx={{ color: 'text.secondary' }} onClick={handleDelete}>
                    <ListItemIcon>
                        <Icon icon={trash2Outline} width={24} height={24} />
                    </ListItemIcon>
                    <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>

                <MenuItem onClick={handleEdit} sx={{ color: 'text.secondary' }}>
                    <ListItemIcon>
                        <Icon icon={editFill} width={24} height={24} />
                    </ListItemIcon>
                    <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
            </Menu>
        </>
    );
}
