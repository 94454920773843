import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
// material
import { styled } from '@mui/material/styles';
import { Box, TextField, InputAdornment } from '@mui/material';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    '& .MuiAutocomplete-root': {
        width: 200,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter
        }),
        '&.Mui-focused': {
            width: 240,
            '& .MuiAutocomplete-inputRoot': {
                boxShadow: theme.customShadows.z12
            }
        }
    },
    '& .MuiAutocomplete-inputRoot': {
        '& fieldset': {
            borderWidth: `1px !important`,
            borderColor: `${theme.palette.grey[500_32]} !important`
        }
    },
    '& .MuiAutocomplete-option': {
        '&:not(:last-child)': {
            borderBottom: `solid 1px ${theme.palette.divider}`
        }
    }
}));

// ----------------------------------------------------------------------

BlogPostsSearch.propTypes = {
    handleSearch: PropTypes.func,
    defaultValue: PropTypes.string
};

BlogPostsSearch.defaultProps = {
    defaultValue: ''
};

export default function BlogPostsSearch({ handleSearch, defaultValue }) {
    const [value, setValue] = useState(defaultValue);
    const onSearch = (e) => {
        const { key } = e;
        if (key === 'Enter') {
            handleSearch(value);
        }
    };

    const handleChange = (e) => {
        const { value } = e.target;
        if (value === '') {
            handleSearch(value);
        }

        setValue(e.target.value);
    };
    return (
        <RootStyle>
            <TextField
                placeholder="Cari postingan..."
                value={value}
                onChange={handleChange}
                onKeyPress={onSearch}
                size="small"
                InputProps={{
                    startAdornment: (
                        <>
                            <InputAdornment position="start">
                                <Box
                                    component={Icon}
                                    icon={searchFill}
                                    sx={{
                                        ml: 1,
                                        width: 20,
                                        height: 20,
                                        color: 'text.disabled'
                                    }}
                                />
                            </InputAdornment>
                        </>
                    )
                }}
            />
        </RootStyle>
    );
}
